import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OCService {

  private oc;

  constructor(private restangular: Restangular) {

      this.oc = this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.baseOCUrl);
      });
  }

  public get() {
    return this.oc;
  }
}
