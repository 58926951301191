import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user/user.service';
import Utils from 'app/shared/utils/utils';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public brandIdentity;

  constructor(
    public usersService: UserService
  ) { }

  ngOnInit() {
    const user = this.usersService.getCurrentUser();

    if (user) {
      const payload = 'e=' + user.email + '&f=' + user.firstName + '&l=' + user.lastName + '&p=' + user.current_vorwerk_roles;
      const uriEnc = encodeURIComponent(payload);
      const b64 = btoa(uriEnc);
      const encoded = Utils.rot13(b64);

      /*if (this.usersService.hasOwnSubsidiaryRole()) {*/
        this.brandIdentity = 'article/52';
        this.brandIdentity = environment.brandIdentityAppUrl + this.brandIdentity;
      /*} else {

        this.brandIdentity = 'brandidentity/help?payload=' + encoded + '&r=' + Utils.randomHash();

        if (!environment.production) {
          this.brandIdentity = environment.prodBaseAppUrl + this.brandIdentity;
        }

      }*/

    }
  }

}
