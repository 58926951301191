import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ErrorsComponent } from 'app/errors/errors.component';
import { HttpErrorResponse } from '@angular/common/http';
import Utils from 'app/shared/utils/utils';
import { AppSettings } from 'app/shared/app.settings';


@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  static instance : ErrorsService;

  private durationInSeconds = 5;
  private utils = Utils;

  constructor(private snackBar: MatSnackBar) {
    if (!ErrorsService.instance) ErrorsService.instance = this;
  }

  showHttpErrorResponse(error : HttpErrorResponse) {

    if (this.ommitError(error)) return;

    let data : any = {error:error, action:this.getAction(error)}
    this.showError(data);

  }

  showInternalError(error) {

    if (this.ommitError(error)) return;

    let data : any = {internal_error:error};
    this.showError(data);

  }

  private ommitError(error) :boolean {

    if (error.url && error.url.indexOf('/assets/i18n/') > -1) return true;
    if (error.rejection && error.rejection instanceof HttpErrorResponse) return true;
    if (error instanceof HttpErrorResponse && error['statusText'] && error['statusText'] === 'Unknown Error') return true;

    console.log(error);

    return false;
  }
  private showError(data) {
    if (AppSettings.SHOW_ERRORS) {
      this.snackBar.openFromComponent(ErrorsComponent, {
        data: data,
        duration: this.durationInSeconds * 1000,
      });
    }
  }

  private getAction(error: any) {

    if (error.request) {
      let method = error.request.method.toLowerCase();
      let action = this.utils.getHttpAction(error.request.url);
      return action + "_" + method;
    }
  }


}
