import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { environment } from '../../../../environments/environment';
import { User } from 'app/shared/model/user.model';
import { AppSettings } from 'app/shared/app.settings';

@Injectable({
  providedIn: 'root'
})
export class HCMSService {

  private hcms;

  constructor(private restangular: Restangular) {

      this.hcms = this.restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.baseHCSMUrl);
      });
  }

  public get() {
    return this.hcms;
  }
  
}
