import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent {

  message;
  internal_message;
  stack;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, @Inject(MatSnackBarRef) public ref: any, private translate:TranslateService) {

    if (data.internal_error && data.internal_error.message) {
      this.internal_message = data.internal_error.message;
    }

    if (data.internal_error && data.internal_error.stack) {
      this.stack = data.internal_error.stack;
    }

    if (data.action) {
      this.message = this.translate.instant(data.action);
    }
  }

}
