import { AppSettings } from './shared/app.settings';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private translate: TranslateService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {

        iconRegistry.addSvgIcon('cntr', sanitizer.bypassSecurityTrustResourceUrl('assets/images/logos/logo.svg'));

        iconRegistry.addSvgIcon('actions', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_actions.svg'));
        iconRegistry.addSvgIcon('library', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_library.svg'));
        iconRegistry.addSvgIcon('projects', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_projects.svg'));
        iconRegistry.addSvgIcon('cicd', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_cicd.svg'));
        iconRegistry.addSvgIcon('img', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_img.svg'));
        iconRegistry.addSvgIcon('help', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_help.svg'));
        iconRegistry.addSvgIcon('next_double', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_next_double.svg'));
        iconRegistry.addSvgIcon('prev_double', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_prev_double.svg'));
        iconRegistry.addSvgIcon('audio', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_audio.svg'));
        
        iconRegistry.addSvgIcon('vw-user', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Profile.svg'));
        iconRegistry.addSvgIcon('vw-password', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Lock-open.svg'));
        iconRegistry.addSvgIcon('vw-email', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Newsletter.svg'));
        iconRegistry.addSvgIcon('vw-delete', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Delete.svg'));
        iconRegistry.addSvgIcon('vw-edit', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Edit.svg'));
        iconRegistry.addSvgIcon('vw-home', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Home.svg'));
        iconRegistry.addSvgIcon('vw-preview', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Visionaries.svg'));
        iconRegistry.addSvgIcon('vw-cart', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Shopping_cart.svg'));
        iconRegistry.addSvgIcon('vw-download', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Download.svg'));
        iconRegistry.addSvgIcon('vw-notification', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Newsletter.svg'));
        iconRegistry.addSvgIcon('vw-prev', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Backward.svg'));
        iconRegistry.addSvgIcon('vw-next', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Forward.svg'));
        iconRegistry.addSvgIcon('vw-save', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_VB100_memory-function.svg'));
        iconRegistry.addSvgIcon('vw-share', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Share.svg'));
        iconRegistry.addSvgIcon('vw-skip-backward', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Skip_Backward.svg'));
        iconRegistry.addSvgIcon('vw-skip-forward', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/VW_Icon_Skip_Forward.svg'));
        
        translate.setDefaultLang(AppSettings.APP_DEFAULT_LANGUAGE);
    }

    ngOnInit() {
    }

    preventDefaultEvent(event){
        event.preventDefault();
    }
}
