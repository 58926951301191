import { ErrorHandler, Injectable} from '@angular/core';
import { ErrorsService } from '../services/errors/errors.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private errorsService:ErrorsService){}

  handleError(error) {

    this.errorsService.showInternalError(error);


     // IMPORTANT: Rethrow the error otherwise it gets swallowed
     //throw error;
  }

}