import { Injectable } from '@angular/core';
import * as Forge from 'node-forge';

@Injectable({
    providedIn: 'root',
})
export class RSAHelper {
    publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCBLUqlHA8IKvO7Cij2JVXRvKx2
  wqODD7Ft/cH7MK/l/pvq63DpT0p45l+yfrTa7RnGIN1PFx4DAf7mP8vBGZi/hbYL
  /XP3B+DPJmERoSI9FxdkaLtDCe8qGLuJg/Sb7iY6XXeAz+5Fdv+Mm8AMW/HIP6kG
  Feuqh2n93Q/N4KtygwIDAQAB
  -----END PUBLIC KEY-----`;

    constructor() { }

    encryptWithPublicKey(valueToEncrypt: string): string {
        const rsa = Forge.pki.publicKeyFromPem(this.publicKey);
        return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
    }
}