import { Injectable } from '@angular/core';
import Utils from 'app/shared/utils/utils';
import { HCMSService } from '../satellites/hcms.service';
import { OCService } from '../satellites/oc.service';
import { Language } from 'app/shared/model/language.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private hcmsService : HCMSService, private ocService: OCService, private translateService: TranslateService) {}

  private languages : Language[]= [
    {id: 'en', label: 'english'},
    {id: 'de', label: 'german'},
  ]

  public getCurrentLanguage() {
    let currentLang = this.translateService.currentLang;
    if (!currentLang) currentLang = this.translateService.defaultLang;
    return currentLang;
  }
  public getLanguages() : Language[] {
    return this.languages;
  }

  public addParent(asset, parent) {

    let route = Utils.getEntity(asset.type);
    if (!route && asset.rawType) {
      route = Utils.getEntity(asset.rawType);
    }

    return this.hcmsService.get().one(route, asset.id).get().toPromise().then(data => {

      if (data) {

        let parents = [];
        if (data.parents) {
          parents = data.parents;
        }
        parents.push(parent.id);
        data.parents = parents;

        return data.put().toPromise();
      }
    });
  }

  public getHistory (id) {
    return this.ocService.get().one('versions', id).get();
  }

}
