import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AppSettings } from 'app/shared/app.settings';


// declare const ATInternet: any;
// export type Tag = any;

declare var window: {
  [key: string]: any;
  prototype: Window;
  new (): Window;
};

export interface PageInfo {
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  customObject?: any;
}

export interface ClickInfo {
  elem: any;
  name: string;
  level2?: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  type: string;
  event?: any;
}

@Injectable()
export class TrackingService {

  // private tag: Tag;
  private matomoCreated: boolean = false;

  constructor() {
    window._paq = window._paq || [];
    // try {
    //   this.tag = new ATInternet.Tracker.Tag();
    // } catch (e) {
    //   console.log(e);
    // }
  }

  // click(info: ClickInfo): boolean {
  //   if (environment.production) {
  //     return this.tag.click.send(info);
  //   } else {
  //     console.log('would track CLICK on live', info);
  //   }
  // }

  // clickListener(info: ClickInfo): void {
  //   if (environment.production) {
  //     this.tag.clickListener.send(info);
  //   } else {
  //     console.log('would track CLICKLISTENER on live', info);
  //   }
  // }

  // pageSend(info: PageInfo): void {
  //   if (environment.production) {
  //     this.tag.page.send(info);
  //   } else {
  //     console.log('would track PAGESEND on live', info);
  //   }
  // }


  isMatomoAvailable() {
    return AppSettings.MATOMO_URL != '' && AppSettings.MATOMO_ID != null && environment.production === true;
  }

  addMatomo(){

    if (this.isMatomoAvailable()){
      // <!-- Matomo -->
      // tracker methods like "setCustomDimension" should be called before "trackPageView" /
      window._paq.push(['trackPageView']);
      window._paq.push(['enableLinkTracking']);
      (function() {
        var u=AppSettings.MATOMO_URL;
        window._paq.push(['setTrackerUrl', u+'matomo.php']);
        window._paq.push(['setSiteId', AppSettings.MATOMO_ID]);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();

      this.matomoCreated = true;
      // <!-- End Matomo Code -->
    }
}

  sendMatomoView(url: string){
      if (this.isMatomoAvailable()){
        if (!this.matomoCreated) this.addMatomo();
        else {
          window._paq.push(['setCustomUrl', url]);
          window._paq.push(['trackPageView']);
        }
    }
  }

  sendMatomoSearch(keyword){
    if (this.isMatomoAvailable()){
      if (!this.matomoCreated) this.addMatomo();
      window._paq.push(['trackSiteSearch', keyword, , ]);
    }
  }

  sendMatomoDownload(assetID){
    if (this.isMatomoAvailable()){
      if (!this.matomoCreated) this.addMatomo();
      var trackVariable = AppSettings.MATOMO_URL  + 'download ' + assetID + '';
      window._paq.push(['trackLink', trackVariable , 'download']);
    }
  }
}
