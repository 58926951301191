// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: 'dev',
  production: false,

  baseAppUrl: 'https://vorwerk-dev.cntr.cloud',
  baseHCSMUrl: 'https://sat.vorwerk-dev.cntr.cloud/hcms/v1.0/',
  baseOCUrl: 'https://metadata.vorwerk-dev.cntr.cloud/',
  recaptchaKey: '6Lf8r6kUAAAAADmuoi35MZLjwjXLXU5c92xPmbcT',

  //baseHCSMUrl: 'http://localhost:8889/',
  //baseOCUrl: 'http://localhost:8888/'

  prodBaseAppUrl: 'https://brandstudio.vorwerk.com/',

  brandIdentityAppUrl: 'https://vorwerk-bi-test.serviceplanmakeos.com/',

  exchangeAppUrl: 'https://exchange.vorwerk.c3n.cloud/index.php/apps/'

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
