export class AppSettings {

    static readonly APP_DEFAULT_LANGUAGE = 'en';

    static readonly APP_PREFIX: string = 'cntr-vorwerk';
    static readonly APP_LOGGIN_PREFIX: string = 'vorwerk-';
    static readonly EXCHANGE_LOGGIN: string = 'exchange-data';
    static readonly DOMAIN: string = 'root.vorwerk_brand_portal.media_library.';
    static readonly DOMAIN2: string = 'root.vi_markets.';
    static readonly WORKFLOW_ID: number = 300;
    static readonly USER_STORAGE: string = "LoggedInProfile";
    static readonly OC_SITE: string = "vorwerk";

    static readonly DEFAULT_OUTPUTCHANNELS = ['root.cntr_vorwerk.dev.', 'root.cntr_vorwerk.staging.', 'root.cntr_vorwerk.live.', 'root.cntr_vorwerk.'];

    static readonly PUBLIC_DOMAIN: string = 'root.vorwerk_brand_portal.media_library.public.';

    //Workflows

    static readonly WORKFLOW_PROJECT: number = 300;
    static readonly WORKFLOW_ACTION: number = 17000;

    static readonly WORKFLOW_ID_PICTURE: number = 20;
    static readonly WORKFLOW_ID_VIDEO: number = 50;
    static readonly WORKFLOW_ID_PDF: number = 4005;
    static readonly WORKFLOW_ID_AUDIO: number = 15;
    static readonly WORKFLOW_ID_BRIEFING: number = 9051;
    static readonly WORKFLOW_ID_OFFICE: number = 1120;
    static readonly WORKFLOW_ID_PRESENTATION: number = 1130;
    static readonly WORKFLOW_ID_ZIP: number = 65;
    static readonly WORKFLOW_ID_WEBPAGE: number = 20;
    static readonly WORKFLOW_ID_SPREADSHEET: number = 5065;

    //GET DATA
    static readonly LIMIT: number = 250;

    //Mails
    static readonly MAIL_PENDINGMATERIALS: string = 'pendingMaterials';
    static readonly MAIL_NEWMATERIAL: string = 'newMaterial';
    static readonly MAIL_NOMATERIALACTION: string = 'noMaterialsAction';
    static readonly MAIL_NOMATERIALACTIONAPPROVED: string = 'noMaterialsActionApproved';
    static readonly MAIL_NEWUSER: string = 'newUser';
    static readonly MAIL_NEWUSERADMINISTRATOR: string = 'newUserAdministrator';
    static readonly MAIL_STARTINAPPROVAL: string = 'startInApproval';
    static readonly MAIL_CONFIRMATION: string = 'confirmation';
    static readonly MAIL_MATERIALACTIONUPDATED: string = 'materialActionUpdated';
    static readonly MAIL_RECOVERY: string = 'recovery';
    static readonly MAIL_ACTION_PM: string = 'actionPM';
    static readonly MAIL_RECOVERY_CONFIRMATION: string = 'recoveryConfirmation';

    static readonly MAIL_NOTIFICATION: string = 'notificationCntr';

    // Tracking
    static readonly ATI_TRACKING_ID: string = '605338';

    // MATOMO
    static readonly MATOMO_URL: string = 'https://matomo.web.pixels-lhgroup.com/';
    static readonly MATOMO_ID: string = '6';

    //SIDEBAR
    static readonly SIDEBAR_CONFIG = [
        { key: 'logo', visible: true },
        { key: 'dashboard', visible: true },
        { key: 'projects', visible: false },
        { key: 'jobs', visible: false },
        { key: 'assets', visible: true },
        { key: 'quickbutton', visible: false }
    ]

    static readonly DELETION_MARK_DELETION: string = '1';
    static readonly DELETION_NONE: string = '0';

    static readonly SHOW_ERRORS: boolean = false;

    static readonly TIME_TO_CANCEL: number = 180;

    static readonly DEFAULT_SECOND_DOMAIN_ROOT = 'root.vi_markets.international.';
    static readonly DEFAULT_SECOND_DOMAIN_USER = ["root.vi_markets.international.europe.dach."];
    static readonly LOGIN_BKG_TAG_ID = 6077453;

    static readonly NUMBER_PAGES: number = 9;

    static readonly DAYS_CHECK_EXPIRE_SOON: number = 90;
    static readonly DOWNLOAD_VARIANT: any = [
        {
            name: "original",
            keys: [
                { key: "downloadLink", mime: "mimetype" }
            ]
        },
        {
            name: "preview",
            keys: [
                { key: "preview", mime: "mimePreview" },
                { key: "pages", arrayKey: "preview", arrayMime: "mimePreview" },
                { key: "mainPictures", arrayKey: "downloadLink", arrayMime: "mimetype" }
            ]
        },
        {
            name: "png",
            keys: [
                { key: "pngDownload", mime: "mimePng" }
            ]
        }
    ]

    static readonly PERMISSIONS: any = {
        defaultDomain: 'root.vorwerk_brand_portal.media_library.public.',
        entities: ['entity/media'],
        permissions: [
            { value: 'root.distributor.', domain: 'root.vorwerk_brand_portal.media_library.distributors.', category: 'root\.international\.international\.all\.', defaultLocation: 'root.international.international.all.' },
            { value: 'root.distributor.thermomix.', domain: 'root.vorwerk_brand_portal.media_library.distributors.', category: 'root\.international\.international\.all(\.thermomix\.|\.vorwerk\.)', defaultLocation: 'root.international.international.all.' },
            { value: 'root.distributor.kobold.', domain: 'root.vorwerk_brand_portal.media_library.distributors.', category: 'root\.international\.international\.all(\.kobold\.|\.vorwerk\.)', defaultLocation: 'root.international.international.all.' },
            { value: 'root.ownsubsidiary.', domain: 'root.vorwerk_brand_portal.media_library.ownsubsidiary.' }
        ]
    }
} 1