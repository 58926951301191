import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, ErrorHandler } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './shared/material/material.module';

import { FooterComponent } from './shared/footer/footer.component';
import { NgxLiquidCacheModule, LiquidCacheConfig, LiquidCacheStorageTypes } from 'ngx-liquid-cache';
import { environment } from 'environments/environment';
import { RestangularModule } from 'ngx-restangular';
import { UserService } from './shared/services/user/user.service';
import { WorkflowService } from './shared/services/workflows/workflow.service';
import { AppSettings } from './shared/app.settings';

import { NgxImageZoomModule } from 'ngx-image-zoom';
import { User } from './shared/model/user.model';
import { ErrorsComponent } from './errors/errors.component';
import { ErrorsService } from './shared/services/errors/errors.service';
import { GlobalErrorHandler } from './shared/errors/global.error';
import { NotificationsComponent } from './shared/components/notifications/notifications.component';

import { OCService } from './shared/services/satellites/oc.service';
import { Router } from '@angular/router';

import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { TrackingService } from './shared/services/tracking/tracking.service';

import { DatePipe } from '@angular/common';


// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const liquidCacheConfig: LiquidCacheConfig = {
    storageType: LiquidCacheStorageTypes.localStorage,
    localStoragePrefix: AppSettings.APP_PREFIX
};

export function RestangularConfigFactory(RestangularProvider) {



    RestangularProvider.setBaseUrl(environment.baseHCSMUrl);
    RestangularProvider.setDefaultHeaders({ 'Access-Control-Allow-Origin': true });
    RestangularProvider.setFullRequestInterceptor(function (element, operation, route, url: string, headers, params, httpConfig) {

        let currentUser: User = JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE));
        if (currentUser) {
            if (operation == 'put') {
                if (element instanceof FormData) {
                    let entity: any = element.get('entity');
                    entity = JSON.parse(entity);

                    entity.modifiedByNew = +currentUser.id;

                    element.set('entity', JSON.stringify(entity));
                } else {
                    element.modifiedByNew = +currentUser.id;
                }
            }
            if (operation == 'post') {

                if (element instanceof FormData) {
                    let entity: any = element.get('entity');
                    entity = JSON.parse(entity);

                    entity.modifiedByNew = +currentUser.id;
                    entity.createdByNew = +currentUser.id;

                    element.set('entity', JSON.stringify(entity));
                } else {
                    element.modifiedByNew = +currentUser.id;
                    element.createdByNew = +currentUser.id;
                }
            }
        }

        let customParams = {};

        if (url.indexOf(environment.baseOCUrl) < 0) {

            let excludeCalls = ['/entity/view?query=idtags'];
            var jwtUser = localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + 'jwtUser');
            if (jwtUser && !excludeCalls.find(x => url.indexOf(x) > -1)) {
                headers.Authorization = jwtUser;
            }

            let permissionCalls = AppSettings.PERMISSIONS.entities;

            if (permissionCalls.includes(route) && currentUser) {

                let permission = AppSettings.PERMISSIONS.permissions.find(x => x.value === currentUser.current_vorwerk_roles);

                customParams['query'] = (params.query ? (params.query + '&') : '') +
                    '(domain="' + AppSettings.PERMISSIONS.defaultDomain +

                    (permission ?
                        '" | domain="' + permission.domain :
                        '')

                    + '")';
            }
        }
        else {
            headers = {
                'Access-Control-Allow-Origin': true
            };
        }


        if (environment.env) customParams['environment'] = environment.env;
        if (AppSettings.OC_SITE) customParams['site'] = AppSettings.OC_SITE;

        params = Object.assign({}, params, customParams);

        return {
            element: element,
            params: params,
            headers: headers,
            httpConfig: httpConfig
        };
    });
    RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
        ErrorsService.instance.showHttpErrorResponse(response);
        if (response.url && response.url.indexOf(environment.baseHCSMUrl) > -1 && response.status === 401 /* && response.data && response.data.error.indexOf('invalid JWT token') > -1*/) {
            localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + 'jwtUser');
            (window as any).location = "/login?expired=true";
        }
        return true;
    });

    RestangularProvider.addResponseInterceptor((data, operation, what, url, response) => {



        if (url.indexOf(environment.baseOCUrl) < 0) {

            let currentUser: User = JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE));
            let permissionCalls = AppSettings.PERMISSIONS.entities;

            if (permissionCalls.includes(what) && currentUser) {

                let permission = AppSettings.PERMISSIONS.permissions.find(x => x.value === currentUser.current_vorwerk_roles);
                if (permission.category) {
                    if (data.result) {
                        data.result = data.result.filter(x => !x.categoryDemoArray || x.categoryDemoArray.some(x => x.match(permission.category)));
                    } else if (!data.categoryDemoArray || !data.categoryDemoArray.some(x => x.match(permission.category))) {
                        data = {};
                    }
                }

            }
        }

        return data;
    });

}

@NgModule({
    declarations: [AppComponent, FooterComponent, ErrorsComponent, NotificationsComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        OverlayModule,
        HttpClientModule,
        FlexLayoutModule.withConfig({ addFlexToParent: false }),
        MaterialModule,
        RestangularModule.forRoot(RestangularConfigFactory),
        NgxLiquidCacheModule.forRoot(liquidCacheConfig),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            isolate: true
        }),
        NgxImageZoomModule.forRoot(),
        TourMatMenuModule.forRoot()
    ],
    entryComponents: [ErrorsComponent, NotificationsComponent],
    providers: [UserService, WorkflowService, ErrorsService, TrackingService, DatePipe,
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
