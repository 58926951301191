import { AppSettings } from "app/shared/app.settings";

export class AuthUser {

    id: number;
    type: string;
    domain: string = AppSettings.DOMAIN;
    domain2: string;
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    language: string = 'en';
    auth: Auth;
    emailStruct: EmailStruct;
    passwordRecovery: GeneratedKey;
    confirmation: GeneratedKey;
    roles: string[] = [];
    rolesGranted: string[] = [];
    active: boolean = false;
    activatedEmailSent : boolean = false;

    vorwerk_roles: string;
}

export class Auth {
    type: string = 'website';
    login: string
    password: string;
}

export class EmailStruct {
    type: string = 'work';
    email: string;
}

export class GeneratedKey {

    key: string;
    timestamp: string;
}